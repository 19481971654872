<template>
  <div class="popup_wrap" style="width:400px;">
    <!--  popup_wrap 팝업사이즈 : width:400px; height:520px; -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONEX020P070.001') }}</h1> <!-- S/R Cancel -->
      <div class="content_box">
        <!-- content_box -->
        <table class="tbl_row fir_td">
          <colgroup>
            <col width="*">
          </colgroup>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="text_left">
                <input type="radio" :id="item.cd + '_' + index" :value="item.cd" name="rmk" v-model="chkCancelCd" />
                <label :for="item.cd + '_' + index"><span></span>{{ item.cdNm }}</label>
              </td>
            </tr>
            <tr v-if="chkCancelCd === '10'">
              <td>
                <div class="position_relative">
                  <input type="text" ref="etc" id="etc" v-model="etc" :placeholder="$t('msg.ONEX020P070.014')" /> <!-- 기타 사유-->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="fnCancel">{{ $t('msg.ONEX020P070.001') }}</a> <!-- S/R Cancel -->
        <a class="button blue lg" v-show="callType == 'LIST'" href="#" @click.prevent="fnMoveDetail">{{ $t('msg.ONEX020P070.012') }}</a> <!-- S/R Detail -->
        <a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.ONEX020P070.013') }}</a> <!-- 닫기 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import srInquiry from '@/api/services/srInquiry'
export default {
  name: 'SHASRCancelPop',
  props: {
    parentInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      srRno: '',
      bkgNo: '',
      chkCancelCd: '',
      etc: '',
      items: [
        { cdNm: this.$t('msg.ONEX020P070.002'), cd: '01' }, //작업일정 변경
        { cdNm: this.$t('msg.ONEX020P070.003'), cd: '02' }, //서류 미비
        { cdNm: this.$t('msg.ONEX020P070.004'), cd: '03' }, //컨테이너 기기 문제
        { cdNm: this.$t('msg.ONEX020P070.005'), cd: '04' }, //선박 스케줄 지연
        { cdNm: this.$t('msg.ONEX020P070.006'), cd: '05' }, //가부킹 / 선사 변경
        { cdNm: this.$t('msg.ONEX020P070.007'), cd: '06' }, //부킹 승인 불가
        { cdNm: this.$t('msg.ONEX020P070.008'), cd: '07' }, //세관 신고, 검역 통과 불가
        { cdNm: this.$t('msg.ONEX020P070.009'), cd: '08' }, //수하인 요청
        { cdNm: this.$t('msg.ONEX020P070.010'), cd: '09' }, //제품문제
        { cdNm: this.$t('msg.ONEX020P070.011'), cd: '10' } //기타
      ]
    }
  },
  created () {
    this.srRno = this.parentInfo.srRno
    this.bkgNo = this.parentInfo.bkgNo
    this.callType = this.parentInfo.callType || 'LIST'
  },
  methods: {
    // cancel 클릭
    async fnCancel () {
      //체크 벨리데이션
      if (!this.chkCancelCd) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P070.015')) //Please check the reason.
        return
      }

      //chkCd와 맞는 걸 가져온 후 앞에 붙이기. chkCd etc인 경우는 다르게
      let filteredReason = this.items.filter(item => {
        return item.cd === this.chkCancelCd
      })[0].cdNm

      //최종적으로 보낼 reason값 셋팅 및 벨리데이션
      let result = ''
      if (this.chkCancelCd === '10') {
        if (!this.etc) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P070.016')) //please input remark
          this.$refs.etc.focus()
          return
        }
        result = this.$t('msg.ONEX020P070.017') + ' -' + this.etc //by shipper - 기타사유
      } else {
        result = this.$t('msg.ONEX020P070.017') + ' -' + filteredReason //by shipper - 사유
      }

      //캔슬 가능여부 체크
      const cancelFlag = await srInquiry.getBkgCancelCd(this.srRno, this.bkgNo)

      if (cancelFlag.data === '99') {
        //부킹 캔슬 처리 시작
        const formData = {
          srRno: this.parentInfo.srRno,
          bkgNo: this.parentInfo.bkgNo,
          cnclRsnRmk: result,
          polPortCd: this.parentInfo.polPortCd
        }

        //S/R Cancel 시작
        const resultData = await srInquiry.putSrCancel(formData)

        //취소 결과에 따른 메세지
        console.log(resultData.data)
        if (resultData.data.cancelResult === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT015')) //적용되었습니다.
          this.$router.push('/working/progress')
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P070.018')) //오류가 발생하였습니다.
        }
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P070.019')) //退关操作不可用, 请联系订舱组负责人
      }
    },
    //상세 이동
    async fnMoveDetail () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.315'), useConfirmBtn: true })) { //부킹 수정 화면으로 이동합니다.
        this.$emit('close')
        this.$router.push({ name: 'booking-edit', params: { bkgNo: this.bkgNo, srRno: this.srRno } }).catch(() => {})
      }
    }
  }
}
</script>
