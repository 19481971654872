var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "400px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX020P070.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_row fir_td" }, [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(_vm.items, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { staticClass: "text_left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.chkCancelCd,
                            expression: "chkCancelCd",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: item.cd + "_" + index,
                          name: "rmk",
                        },
                        domProps: {
                          value: item.cd,
                          checked: _vm._q(_vm.chkCancelCd, item.cd),
                        },
                        on: {
                          change: function ($event) {
                            _vm.chkCancelCd = item.cd
                          },
                        },
                      }),
                      _c("label", { attrs: { for: item.cd + "_" + index } }, [
                        _c("span"),
                        _vm._v(_vm._s(item.cdNm)),
                      ]),
                    ]),
                  ])
                }),
                _vm.chkCancelCd === "10"
                  ? _c("tr", [
                      _c("td", [
                        _c("div", { staticClass: "position_relative" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.etc,
                                expression: "etc",
                              },
                            ],
                            ref: "etc",
                            attrs: {
                              type: "text",
                              id: "etc",
                              placeholder: _vm.$t("msg.ONEX020P070.014"),
                            },
                            domProps: { value: _vm.etc },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.etc = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fnCancel.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P070.001")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.callType == "LIST",
                  expression: "callType == 'LIST'",
                },
              ],
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fnMoveDetail.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P070.012")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P070.013")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "*" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }